// @font-face {
//   font-family: 'sosa';
//   src: url('@{icon-font-path}sosa-regular-webfont.eot');
//   src: //local(sosa),
//        url('@{icon-font-path}sosa-regular-webfont.eot?#iefix') format('embedded-opentype'),
//        url('@{icon-font-path}sosa-regular-webfont.woff') format('woff'),
//        url('@{icon-font-path}sosa-regular-webfont.ttf') format('truetype'),
//        url('@{icon-font-path}sosa-regular-webfont.svg#sosaregular') format('svg');
//   font-weight: normal;
//   font-style: normal;
// }


@font-face {
  font-family: 'Crimson Text';
  font-style: normal;
  font-weight: 400;
  src: url('@{icon-font-path}crimsontext-roman-webfont.eot');
  src: //local('Crimson Text'), local('CrimsonText-Roman'),
       url('@{icon-font-path}crimsontext-roman-webfont.eot?#iefix') format('embedded-opentype'),
       url('@{icon-font-path}crimsontext-roman-webfont.woff') format('woff'),
       url('@{icon-font-path}crimsontext-roman-webfont.ttf') format('truetype'),
       url('@{icon-font-path}crimsontext-roman-webfont.svg#crimson_textroman') format('svg');
}

// @font-face {
//   font-family: 'Crimson Text';
//   font-style: normal;
//   font-weight: 600;
//   src: url('@{icon-font-path}crimsontext-semibold-webfont.eot');
//   src: //local('Crimson Text Semibold'), local('CrimsonText-Semibold'),
//        url('@{icon-font-path}crimsontext-semibold-webfont.eot?#iefix') format('embedded-opentype'),
//        url('@{icon-font-path}crimsontext-semibold-webfont.woff') format('woff'),
//        url('@{icon-font-path}crimsontext-semibold-webfont.ttf') format('truetype'),
//        url('@{icon-font-path}crimsontext-semibold-webfont.svg#crimson_textsemibold') format('svg');
// }

//@font-face {
//  font-family: 'Crimson Text';
//  font-style: normal;
//  font-weight: 700;
//  src: url('@{icon-font-path}crimsontext-bold-webfont.eot');
//  src: //local('Crimson Text Bold'), local('CrimsonText-Bold'),
//       url('@{icon-font-path}crimsontext-bold-webfont.eot?#iefix') format('embedded-opentype'),
//       url('@{icon-font-path}crimsontext-bold-webfont.woff') format('woff'),
//       url('@{icon-font-path}crimsontext-bold-webfont.ttf') format('truetype'),
//       url('@{icon-font-path}crimsontext-bold-webfont.svg#crimson_textbold') format('svg');
//}

@font-face {
font-family: 'Crimson Text';
  font-style: italic;
  font-weight: 400;
  src: url('@{icon-font-path}crimsontext-italic-webfont.eot');
  src: //local('Crimson Text Italic'), local('CrimsonText-Italic'),
       url('@{icon-font-path}crimsontext-italic-webfont.eot?#iefix') format('embedded-opentype'),
       url('@{icon-font-path}crimsontext-italic-webfont.woff') format('woff'),
       url('@{icon-font-path}crimsontext-italic-webfont.ttf') format('truetype'),
       url('@{icon-font-path}crimsontext-italic-webfont.svg#crimson_textitalic') format('svg');
}

// @font-face {
//   font-family: 'Crimson Text';
//   font-style: italic;
//   font-weight: 600;
//   src: url('@{icon-font-path}crimsontext-semibolditalic-webfont.eot');
//   src: //local('Crimson Text Semibold Italic'), local('CrimsonText-SemiboldItalic'),
//        url('@{icon-font-path}crimsontext-semibolditalic-webfont.eot?#iefix') format('embedded-opentype'),
//        url('@{icon-font-path}crimsontext-semibolditalic-webfont.woff') format('woff'),
//        url('@{icon-font-path}crimsontext-semibolditalic-webfont.ttf') format('truetype'),
//        url('@{icon-font-path}crimsontext-semibolditalic-webfont.svg#crimson_textsemibold_italic') format('svg');
// }

//@font-face {
//  font-family: 'Crimson Text';
//  font-style: italic;
//  font-weight: 700;
//  src: url('@{icon-font-path}crimsontext-bolditalic-webfont.eot');
//  src: //local('Crimson Text Bold Italic'), local('CrimsonText-BoldItalic'),
//       url('@{icon-font-path}crimsontext-bolditalic-webfont.eot?#iefix') format('embedded-opentype'),
//       url('@{icon-font-path}crimsontext-bolditalic-webfont.woff') format('woff'),
//       url('@{icon-font-path}crimsontext-bolditalic-webfont.ttf') format('truetype'),
//       url('@{icon-font-path}crimsontext-bolditalic-webfont.svg#crimson_textbold_italic') format('svg');
//}

// @font-face {
//   font-family: 'Source Sans Pro';
//   font-style: normal;
//   font-weight: 200;
//   src: url('@{icon-font-path}sourcesanspro-extralight-webfont.eot');
//   src: //local('Source Sans Pro ExtraLight'), local('SourceSansPro-ExtraLight'),
//        url('@{icon-font-path}sourcesanspro-extralight-webfont.eot?#iefix') format('embedded-opentype'),
//        url('@{icon-font-path}sourcesanspro-extralight-webfont.woff') format('woff'),
//        url('@{icon-font-path}sourcesanspro-extralight-webfont.ttf') format('truetype'),
//        url('@{icon-font-path}sourcesanspro-extralight-webfont.svg#source_sans_proextralight') format('svg');
// }

@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 300;
  src: url('@{icon-font-path}sourcesanspro-light-webfont.eot');
  src: //local('Source Sans Pro Light'), local('SourceSansPro-Light'),
       url('@{icon-font-path}sourcesanspro-light-webfont.eot?#iefix') format('embedded-opentype'),
       url('@{icon-font-path}sourcesanspro-light-webfont.woff') format('woff'),
       url('@{icon-font-path}sourcesanspro-light-webfont.ttf') format('truetype'),
       url('@{icon-font-path}sourcesanspro-light-webfont.svg#source_sans_prolight') format('svg');
}

@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  src: url('@{icon-font-path}sourcesanspro-regular-webfont.eot');
  src: //local('Source Sans Pro'), local('SourceSansPro-Regular'),
       url('@{icon-font-path}sourcesanspro-regular-webfont.eot?#iefix') format('embedded-opentype'),
       url('@{icon-font-path}sourcesanspro-regular-webfont.woff') format('woff'),
       url('@{icon-font-path}sourcesanspro-regular-webfont.ttf') format('truetype'),
       url('@{icon-font-path}sourcesanspro-regular-webfont.svg#source_sans_proregular') format('svg');
}

@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 600;
  src: url('@{icon-font-path}sourcesanspro-semibold-webfont.eot');
  src: //local('Source Sans Pro Semibold'), local('SourceSansPro-Semibold'),
       url('@{icon-font-path}sourcesanspro-semibold-webfont.eot?#iefix') format('embedded-opentype'),
       url('@{icon-font-path}sourcesanspro-semibold-webfont.woff') format('woff'),
       url('@{icon-font-path}sourcesanspro-semibold-webfont.ttf') format('truetype'),
       url('@{icon-font-path}sourcesanspro-semibold-webfont.svg#source_sans_prosemibold') format('svg');
}

@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 700;
  src: url('@{icon-font-path}sourcesanspro-bold-webfont.eot');
  src: //local('Source Sans Pro Bold'), local('SourceSansPro-Bold'),
       url('@{icon-font-path}sourcesanspro-bold-webfont.eot?#iefix') format('embedded-opentype'),
       url('@{icon-font-path}sourcesanspro-bold-webfont.woff') format('woff'),
       url('@{icon-font-path}sourcesanspro-bold-webfont.ttf') format('truetype'),
       url('@{icon-font-path}sourcesanspro-bold-webfont.svg#source_sans_probold') format('svg');
}

// @font-face {
//   font-family: 'Source Sans Pro';
//   font-style: normal;
//   font-weight: 900;
//   src: url('@{icon-font-path}sourcesanspro-black-webfont.eot');
//   src: //local('Source Sans Pro Black'), local('SourceSansPro-Black'),
//        url('@{icon-font-path}sourcesanspro-black-webfont.eot?#iefix') format('embedded-opentype'),
//        url('@{icon-font-path}sourcesanspro-black-webfont.woff') format('woff'),
//        url('@{icon-font-path}sourcesanspro-black-webfont.ttf') format('truetype'),
//        url('@{icon-font-path}sourcesanspro-black-webfont.svg#source_sans_problack') format('svg');
// }

// @font-face {
//   font-family: 'Source Sans Pro';
//   font-style: italic;
//   font-weight: 200;
//   src: url('@{icon-font-path}sourcesanspro-extralightitalic-webfont.eot');
//   src: //local('Source Sans Pro ExtraLight Italic'), local('SourceSansPro-ExtraLightIt'),
//        url('@{icon-font-path}sourcesanspro-extralightitalic-webfont.eot?#iefix') format('embedded-opentype'),
//        url('@{icon-font-path}sourcesanspro-extralightitalic-webfont.woff') format('woff'),
//        url('@{icon-font-path}sourcesanspro-extralightitalic-webfont.ttf') format('truetype'),
//        url('@{icon-font-path}sourcesanspro-extralightitalic-webfont.svg#source_sans_proXLtIt') format('svg');
// }

@font-face {
  font-family: 'Source Sans Pro';
  font-style: italic;
  font-weight: 300;
  src: url('@{icon-font-path}sourcesanspro-lightitalic-webfont.eot');
  src: //local('Source Sans Pro Light Italic'), local('SourceSansPro-LightIt'),
       url('@{icon-font-path}sourcesanspro-lightitalic-webfont.eot?#iefix') format('embedded-opentype'),
       url('@{icon-font-path}sourcesanspro-lightitalic-webfont.woff') format('woff'),
       url('@{icon-font-path}sourcesanspro-lightitalic-webfont.ttf') format('truetype'),
       url('@{icon-font-path}sourcesanspro-lightitalic-webfont.svg#source_sans_prolight_italic') format('svg');
}

@font-face {
  font-family: 'Source Sans Pro';
  font-style: italic;
  font-weight: 400;
  src: url('@{icon-font-path}sourcesanspro-italic-webfont.eot');
  src: //local('Source Sans Pro Italic'), local('SourceSansPro-It'),
       url('@{icon-font-path}sourcesanspro-italic-webfont.eot?#iefix') format('embedded-opentype'),
       url('@{icon-font-path}sourcesanspro-italic-webfont.woff') format('woff'),
       url('@{icon-font-path}sourcesanspro-italic-webfont.ttf') format('truetype'),
       url('@{icon-font-path}sourcesanspro-italic-webfont.svg#source_sans_proitalic') format('svg');
}

//@font-face {
//  font-family: 'Source Sans Pro';
//  font-style: italic;
//  font-weight: 600;
//  src: url('@{icon-font-path}sourcesanspro-semibolditalic-webfont.eot');
//  src: //local('Source Sans Pro Semibold Italic'), local('SourceSansPro-SemiboldIt'),
//       url('@{icon-font-path}sourcesanspro-semibolditalic-webfont.eot?#iefix') format('embedded-opentype'),
//       url('@{icon-font-path}sourcesanspro-semibolditalic-webfont.woff') format('woff'),
//       url('@{icon-font-path}sourcesanspro-semibolditalic-webfont.ttf') format('truetype'),
//       url('@{icon-font-path}sourcesanspro-semibolditalic-webfont.svg#source_sans_proSBdIt') format('svg');
//}

@font-face {
  font-family: 'Source Sans Pro';
  font-style: italic;
  font-weight: 700;
  src: url('@{icon-font-path}sourcesanspro-bolditalic-webfont.eot');
  src: //local('Source Sans Pro Bold Italic'), local('SourceSansPro-BoldIt'),
       url('@{icon-font-path}sourcesanspro-bolditalic-webfont.eot?#iefix') format('embedded-opentype'),
       url('@{icon-font-path}sourcesanspro-bolditalic-webfont.woff') format('woff'),
       url('@{icon-font-path}sourcesanspro-bolditalic-webfont.ttf') format('truetype'),
       url('@{icon-font-path}sourcesanspro-bolditalic-webfont.svg#source_sans_probold_italic') format('svg');
}

// @font-face {
//   font-family: 'Source Sans Pro';
//   font-style: italic;
//   font-weight: 900;
//   src: url('@{icon-font-path}sourcesanspro-blackitalic-webfont.eot');
//   src: //local('Source Sans Pro Black Italic'), local('SourceSansPro-BlackIt'),
//        url('@{icon-font-path}sourcesanspro-blackitalic-webfont.eot?#iefix') format('embedded-opentype'),
//        url('@{icon-font-path}sourcesanspro-blackitalic-webfont.woff') format('woff'),
//        url('@{icon-font-path}sourcesanspro-blackitalic-webfont.ttf') format('truetype'),
//        url('@{icon-font-path}sourcesanspro-blackitalic-webfont.svg#source_sans_problack_italic') format('svg');
// }

