// Default brand colour.  This would normally be overridden by setting the value at
// a later point in the importing style sheet.
// Advice on what colours can be used while maintaining the University
// brand is available from Communications and Marketing.

.mixin-text-contrast(@n) {
  @color-brightness: round(((red(@n)*299)+(green(@n)*587)+(blue(@n)*114)/1000));
  @light-color: round(((red(#ffffff)*299)+(green(#ffffff)*587)+(blue(#ffffff)*114)/1000));
  
  & when (abs(@color-brightness) < (@light-color/1.4)) {
    color: #fff;
  }
  & when (abs(@color-brightness) >= (@light-color/1.4)) {
    color: #000;
  }
}

.mixin-link-contrast(@n) {
  @color-brightness: round(((red(@n)*299)+(green(@n)*587)+(blue(@n)*114)/1000));
  @light-color: round(((red(#ffffff)*299)+(green(#ffffff)*587)+(blue(#ffffff)*114)/1000));
  
  & when (abs(@color-brightness) < (@light-color/1.4)) {
    color: @n;
  }
  & when (abs(@color-brightness) >= (@light-color/1.4)) {
    color: #333;
  }
}

.mixin-hover-contrast(@n) {
  @color-brightness: round(((red(@n)*299)+(green(@n)*587)+(blue(@n)*114)/1000));
  @light-color: round(((red(#ffffff)*299)+(green(#ffffff)*587)+(blue(#ffffff)*114)/1000));
  
  & when (abs(@color-brightness) < (@light-color/1.4)) {
    color: darken(@n, 5%);
  }
  & when (abs(@color-brightness) >= (@light-color/1.4)) {
    color: #000;
  }
}
