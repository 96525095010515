.discover-uni {
  background: #252525;
  font-size: 1.2em;
  margin: 0;
  padding: 0 0 15px;
  h3 {
    color: #777;
    font-size: 1.5em;
    margin: 0;
    padding: 20px 0 5px;
    text-transform: uppercase;
    font-family: @font-family-serif;
    font-weight: 400;
  }
}

.footer {
  color: #ccc;
  font-size: 1em;
  background-color: #333333;
  background-color: rgba(0, 0, 0, 0);
  background: linear-gradient(to bottom, #333333 0%, #262626 100%) repeat scroll 0 0;
  border-top: none;
  margin: 0;
  padding: 16px 0;
// Source Sans Pro Black (weight 900) is not currently loaded.
//  h3 {
//    font-family: 'Source Sans Pro', sans-serif;
//    font-size: 1em;
//    letter-spacing: 0.01em;
//    text-transform: uppercase;
//    a, a:visited {font-weight: 900; color: #fff;}
//  }
  ul {padding-left: 0;
    list-style: none;
    line-height: 1.42857;
  }
  a, a:visited {color: #ccc;}
  a:hover {color: #fff;}
  .btn {
    border: none;
    color: #000;
    margin-top: 1.0em;
    margin-bottom: 1.0em;
   }
  .container .row {
    padding-bottom: 10px;
  }
  .pull-right {
    @media (max-width: @screen-xs-max) {
      float: none !important;
    }
  }
}

.footer-copyright {background: #FFF; color: #444;}
.copyright, .login-link {color: #666; font-size: 0.8em;}
.copyright {padding-top: 20px; padding-bottom: 20px;}
.login-link:hover, .login-link:active {text-decoration: none;}

  
// Subsite footer
// ---------------------------------------------------------------------------------------------------------------------
.subsite-affiliations {
  background-color: #fff;
  margin: 0;
  padding: 30px 0 16px;
  .affiliation-image {
     margin: 0;
     padding: 0 0 16px 30px;
     float: left;
     img {
       max-height: 75px;
       width: auto;
     }
  }
}  

.subsite-footer-content {
  background-color: #666;
  color: #fff;
  margin: 0;
  padding: 30px 0 0;
  .row > div {
    padding-bottom: 16px;
  }
  h3 {
    color: #fff;
    margin-top: 0;
    font-weight: 400;
  }
  ul {
    line-height: 1.428571429;
  }
  a:not(.btn) {
    color: #fff;
    font-size: 1.2em;
    font-weight: 300;
    &:hover {
      opacity: 0.75;
    }
  }
}

