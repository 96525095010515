.panel.uoe-overview, .panel.uoe-overview-medium {
  .panel-heading {
    background: none;
    box-shadow: none;
    .panel-title, h3 {
      .mixin-link-contrast(@brand);
    }
    h3:hover {
      .mixin-hover-contrast(@brand);
    }
  }
}

.panel.uoe-overview-medium {
  box-shadow: 0 4px 6px -5px rgba(0, 0, 0, 0.3), 0 0 1px rgba(0, 0, 0, 0.1), inset 0 7px 0 @brand;
}

.panel.uoe-overview, .panel.uoe-overview-medium, .panel.uoe-overview-high {
  .panel-heading {
    box-shadow: none;
    .panel-title {
      margin: 5px 0;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  a:hover, a:active {
    text-decoration: none;
  }
  .panel-body {
    a:hover {
      text-decoration: underline;
    }
  }
}

.panel.uoe-overview-high {
  .panel-heading {
    border-color: #ddd;
    border-color: rgba(255, 255, 255, 0.5);
    border-width: 0;
    background: @brand;
    &:hover {
      background: darken(@brand, 5%);
    }
    h3 {
      .mixin-text-contrast(@brand); 
    }
  }
}

.panel.uoe-overview-high a, .panel.uoe-overview-high a:hover {
  text-decoration: none;
}
.panel.uoe-overview-high a:hover h3 {
  text-decoration: underline;
}
