/* header container
------------------------------------------------------------------------------- */

.jumbotron.headercontainer {
  background: #ffffff;
  margin: 0;
  position: relative;
  .row img {width: 85%; height: auto; max-width: 332px; max-height: 79px;}
  p {
    font-weight: 700;
    font-size: (@jumbotron-font-size * 0.85);
    line-height: (@jumbotron-font-size / 3 * 4);
  }
  .input-group, .form-group {
    margin-bottom: 0;
    width: 100%;
  }
  .input-group .input-group-btn {
    width: 1%;
  }
  .input-group-btn .btn {
    padding-top: 10px;
  }
  a {
    &, &:visited {
      .mixin-link-contrast(@brand);
    }
    &:hover {
      .mixin-hover-contrast(@brand);
    }
  }
}


/* Show/hide the search form
------------------------------------------------------------------------------- */

.uoe-search-trigger {
  display: none;
  position: absolute;
  font-size: 21px;
  right: 0;
  top: 0;
  cursor: pointer;
  padding: 10px 10px;
}

@media only screen and (max-width: @screen-xs-max) {
  .jumbotron.headercontainer {padding: 5px 0;}
  .jumbotron.headercontainer p {margin: 5px 0;}
  .uoe-search-trigger {
    display: block;
  }
  .jumbotron .uoe-search-block {
    display: none;
    padding-bottom: 5px;
  }
}

/* Ensure search form always open on larger screens whatever slideToggle() has done. */
@media only screen and (min-width: @screen-sm-min) {
  .jumbotron .uoe-search-block {
    display: block !important;
  }
}



/* home-hero
------------------------------------------------------------------------------- */

.home-hero {
  background: @brand;
  padding: 0;
  margin: 0;
  img {padding: 0; width: 100%; margin: 0;}
  position: relative;
  .brand-logo-container {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
  }
  .brand-block {
    border-top: 4px solid @brand;
  }
  .titleblock {
    display: table-cell;
    vertical-align: middle;
    height: 70px; /* Min-height for table-cell */
    font-size: 1.5em;
    padding-top: 1.5em;
    padding-bottom: 1.3em;
    position: relative;
    .h1, h1 {
      display: block;
      font-size: 1.6em;
      font-style: normal;
      font-weight: 400;
      line-height: 1;
      margin: 0 0;
      text-transform: uppercase;
    }
    .h2, h2 {
      display: block;
      font-size: 1.4em; 
      font-style: italic; 
      margin: 0 0 8px 0;
    }
    a, a:visited {
      .mixin-text-contrast(@brand);
      text-decoration: none;
    }
    a:hover {text-decoration: underline;}
  }
}

@media screen and (max-width: @screen-xs-max) {
  .home-hero .titleblock {
    font-size: 1em;
    padding-top: 0.8em;
    padding-bottom: 0.6em;
  }
}


/* Admin Interface Header */
.admin-bar {
  padding-bottom: 10px;
  h1, h2, h3, h4, h5, h6 {
    font-family: @font-family-serif;
    font-size: 2em;
    font-weight: 400;
    margin-top: 15px !important;
  }
}

.uoe-local-tasks {
  .primary {
    padding-bottom: 0.5rem;
    border-bottom: solid #ffffff 1px;
    li {
      line-height: 2.7;
    }
  }
  .secondary {
    float: right;
    li {
      line-height: 2.1;
    }
  }
}
