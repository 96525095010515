.uoe-contact {
  img {
    width: 100px;
    margin-bottom: 15px;
  }

  ul {
    list-style-type: none;
    padding: 0;
    margin: 0 0 15px;

    li {
      margin-bottom: 5px;
    }
  }
  
  .separator {
    height: 1px;
    background: #ddd;
    margin: 15px 0 20px;
  }
  
  .organizations {
    font-weight: 600;
    margin-bottom: 1.25em;
  }

  .dropdown {
    margin-top: 15px;
  }

  .open > .dropdown-menu {
    margin-top: 2px;
    padding-top: 10px;
    padding-bottom: 5px;
  }

  .glyphicon {
    margin-right: 0.5em;
  }
  
}
