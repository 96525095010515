/* Bootstrap additions
------------------------------------------------------------------------------- */
.container, .jumbotron .container {
  width: 100%;
  max-width: 90rem;
  padding-left: 15px;
  padding-right: 15px;
}

@media screen and (min-width: @screen-sm-min) {
  .container, .jumbotron .container {
    padding-left: 30px;
    padding-right: 30px;
  }
}
a {
  h1, h2, h3, h4, h5, h6, p, ul, ol {
    color: #428bca;
  }
}

.page-header {
  margin: 0 0 10px;
  padding-top: 0;
}

.alert {margin-top: 20px;}
.alert > .glyphicon {float: left; padding-right: 1em;}

.modal-content .modal-body {
  overflow: auto;
}

p:last-child {
  margin-bottom: 0;
}
p.lead {
  font-size: 1.3em;
  font-weight: 400;
  &:last-child {
    margin-bottom: 20px;
  }
}

ul.pagination > li > a {
  &.progress-disabled {
    float: left;
  }
}


/* thumbnail boxes
------------------------------------------------------------------------------- */

.shade {box-shadow: 0 4px 6px -5px rgba(0, 0, 0, 0.3), 0 0 1px rgba(0, 0, 0, 0.1);}

.panel {
  &:extend(.shade, .clearfix);
  display: block; background: #fff; border-radius: 0; border: none;
  &:last-child {
    margin-bottom: 20px;
  }
}

.nav-tabs a:focus {outline: none;}
.nav-tabs > li > a {background: #ddd;}
.nav-tabs > li > a:hover {background: #d5d5d5;}
.tab-content:extend(.panel) {
  padding: 25px 16px 10px;
}

.thumbnail:extend(.panel) {
  padding: 0;
}


/* forms
------------------------------------------------------------------------------- */
.form-control { /* required for extend() */
  height: 45px;
  font-size: 16px;
  background-color: #e1e1e1;
  color: #555555;
  border: 1px solid #cccccc;
  border-radius: 4px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset;
  display: block;
  padding: 6px 12px;
  transition: border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s;
  width: 100%;
}
.form-control-feedback {font-size: 16px; height: 45px;}
.form-control-feedback {line-height: 45px;}
.form-control-feedback + .input-group-addon,
.form-control-feedback + span + .input-group-addon {padding-right: 35px;}

td .form-group {
  margin: 0 0;
  * {
    margin-bottom: 0;
  }
}

.btn:focus {outline: thin dotted #000000;}
.btn-default.disabled, .btn-default[disabled], fieldset[disabled] .btn-default,
.btn-default.disabled:hover, .btn-default[disabled]:hover, fieldset[disabled] .btn-default:hover,
.btn-default.disabled:focus, .btn-default[disabled]:focus, fieldset[disabled] .btn-default:focus,
.btn-default.disabled:active, .btn-default[disabled]:active, fieldset[disabled] .btn-default:active,
.btn-default.disabled.active, .btn-default.active[disabled], fieldset[disabled] .btn-default.active {
  background-color: #ddd;
}

.btn + a:not(.btn) {margin-left: 3em;}

.input-group-btn > .btn {
  height: 45px;
}

input.form-file {
  margin-top: 0;
  margin-bottom: 10px;
  padding-top: 0;
}

.form-inline .panel-body {
  display: block;
  padding: 15px 15px;
}

th {
  .checkbox input[type="checkbox"],
  .checkbox-inline input[type="checkbox"],
  .radio input[type="radio"],
  .radio-inline input[type="radio"] {
    position: relative;
  }
}
.checkbox input[type="checkbox"], 
.checkbox-inline input[type="checkbox"], 
.radio input[type="radio"], 
.radio-inline input[type="radio"] {
  margin-left: 0;
}
.checkbox label,
.checkbox-inline label,
label.checkbox,
label.checkbox-inline,
label .checkbox,
label .checkbox-inline {
  input[type="checkbox"] {
        margin-left: -20px;
  }
}
.radio label,
.radio-inline label,
label.radio,
label.radio-inline,
label .radio,
label .radio-inline {
  input[type="radio"] {
        margin-left: -20px;
  }
}

label {
  &.radio-inline,
  &.checkbox-inline {
    padding-right: 15px;
  }
}

.form-horizontal .form-group label {
  & + * .form-item {
    label {
      padding: 0 0 0 20px;
      float: none;
      min-height: 20px;
      text-align: left;
    }
  }
}

.help-block {
    color: #686868;
    font-size: 12px;
    margin: 5px 0 15px;
    padding: 0;
}
.form-group + .help-block {
  margin-top: -10px;
}
@media screen and (min-width: @screen-sm-min) {
  .form-horizontal .help-block {
    margin-left: 25%;
    padding-left: 5px;
  }
}

legend a:focus {
  outline: initial;
}

/* tables
------------------------------------------------------------------------------- */
table th {
  a {
    display: block;
    position: relative;
  }
  &.active {
    a {
      padding: 0 25px 0 0;
    }
    img {
      position: absolute;
      right: 3px;
      top: 3px;
    }
  }
}

.table-striped tr {
  background-color: #fff;
}

td.checkbox, th.checkbox {
  margin: 0 0;
}

/* headings
------------------------------------------------------------------------------- */

form legend {font-size: 1.65em;}
h5 {font-weight: 500;}
h6 {
  font-weight: 600;
  small {font-weight: 400; }
}

.jumbotron {
  h1, h2, h3, h4, h5, h6,
  .h1, .h2, .h3, .h4, .h5, .h6 {
    font-family: @font-family-serif;
    font-weight: 400;
  }
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  clear: left;
  small, .small {font-weight: 300;}
}
h3, h4, h5, h6,
.h3, .h4, .h5, .h6 {
  color: #666;
}
h4, .h4 {
  margin-top: 20px;
}

h1 + h2, h2 + h3, h3 + h4, h4 + h5, h5 + h6 {
  margin-top: -4px;
}
