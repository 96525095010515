/* bootstrap navbar
------------------------------------------------------------------------------- */
.navbar-nav {
  margin-top: 0;
  margin-bottom: 0;
}
.navbar-default {
  border-width: 0;
  .navbar-nav > li {
    background-color: @navbar-default-link-color-bg;
    border-left: 1px solid @navbar-default-border;
    &:last-of-type {
      border-right: 1px solid @navbar-default-border;
    }
    a {
      @media (max-width: @grid-float-breakpoint-max) {
        padding: 1em 1.6em;
      }
      line-height: 10px;
      @media (min-width: @grid-float-breakpoint) {
        line-height: 20px;
      }
    }
  }
}

@media (max-width: (@grid-float-breakpoint-max)) {
  .navbar-nav {
    li {
      border-bottom: 1px solid @navbar-default-border !important;
      .dropdown-menu {
        padding-top: 1px;
      }
    }
  }
}
@media (min-width: @screen-xs-min) and (max-width: (@grid-float-breakpoint-max)) {
  .navbar-default {
    li {
      width: 33.33%;
      float: left;
      li {
        width: 100%;
      }
    }
  }
}
@media (min-width: @screen-xs-min) and (max-width: 550px) {
  .navbar-default {
    li {
      width: 50%;
      li {
        width: 100%;
        float: none;
      }
    }
  }
}

.tabs-left > .nav-tabs {
  padding-left: 0;
}


/* breadcrumb
------------------------------------------------------------------------------- */

.page-header.brand {
  padding-bottom: 9px;
  margin-bottom: 0;
  border-bottom: 10px solid @brand;
}
.breadtrail {
  margin: 0 -10px;
  & > div {
    margin-bottom: 20px;
    padding: 0 10px;
  }
  & > div:last-child {
    margin-bottom: 30px;
  }
  .btn.btn-uoe {
    padding-left: 2px;
    padding-right: 2px;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 0.9em;
    border: none;
  }
}
.breadcrumb {
  font-size: 0.9em;
  margin-bottom: 0;
  line-height: @line-height-base;
  a, a:visited {
    color: @breadcrumb-color;
    display: inline-block;
  }
  > li {
    + li:before {
      padding: 0 7px;
    }
    &:last-child,
    &:last-child a,
    &:last-child a:visited {
      color: #000;
      text-decoration: none;
      cursor: default;
    }
  }
}

/* buttons
------------------------------------------------------------------------------- */
.btn.contact-link, .btn.btn-uoe, .badge.badge-uoe {
  background: @brand;
  border-color: @brand; 
  .mixin-text-contrast(@brand);
  &:hover {
    background: darken(@brand, 5%); border-color: darken(@brand, 5%); .mixin-text-contrast(@brand);    
  }
}

.btn.contact-link .badge, .btn.btn-uoe .badge {
  .mixin-link-contrast(@brand);
  background: #fff;
  border-color: #fff; 
}


/* main navigation menu
------------------------------------------------------------------------------- */

.uoe-nav-trigger {
  display: block;
  background: #fff;
  margin: 0 0;
  padding: 18px 15px;
  position: relative;
  font-weight: 700;
  font-size: 1em;
  border-bottom: 1px solid #666;
  cursor: pointer;

  .glyphicon {
    font-size: 1.3em;
    font-weight: 400;
    padding-right: 10px;
    vertical-align: sub;
  }
}

// Left content nav.
.uoe-left-nav {
  box-shadow: 0 4px 6px -5px rgba(0, 0, 0, 0.3);
  border-top: 1px solid #f5f5f5;
  margin: 0;
  padding: 0;
  list-style: none;
  .uoe-left-nav {box-shadow: none;}
  .glyphicon {
    color: @brand;
    font-size: 1.25em;
  }
  a:hover .glyphicon {
    color: darken(@brand, 5%);
  }
  ul {
    list-style: none;
    display: none;
    font-size: 1em;
    margin: 0 0;
    padding: 0 0;
  }
  li > ul {
    display: block;
  }
  // Default list item settings.
  li {
    background: #fff;
    > a {
      background: transparent;
      display: block;
      line-height: 1.2em;
      color: #555;
      font-size: 0.95em;
      font-weight: 600;
      &:hover, &:focus {
        text-decoration: none;
        background: #fafafa;
        color: #111;
        border-color: #ccc;
      }
    }
    &.active, &.expanded.active {
      > a {
        background: @brand;
        &, .glyphicon {
          .mixin-text-contrast(@brand);
        }
        &:hover {
          background: darken(@brand, 5%);
          &, .glyphicon {
            .mixin-text-contrast(@brand);
          }
        }
      }
    }
  }
  // Group page list item.
  > li {
    a {
      border-bottom: 1px solid #ccc;
      padding: 20px 15px;
    }
    > ul {
      // Ancestors of the expanded list item.
      .ancestor {
        padding-top: 1px;
        margin: 0 0 !important;
        > a {
          background: #fafafa;
          border-bottom: 1px solid #dddddd !important;
          font-size: 12px !important;
          font-size: 0.8rem !important;
          margin: -1px 0 0 0 !important;
          opacity: 0.9;
          padding: 10px 15px !important;
          color: #555 !important;
          &:hover, &:focus {
            color: #111 !important;
          }
        }
        > ul {
          padding: 0 0;
        }
      }

      // Immediate children of the group list item.
      > li {
        > a {
          padding: 15px 15px;
        }
        &.expanded {
          > a {
            border-bottom: none;
            color: @brand;
            &:hover, &:focus {
              color: darken(@brand, 5%);
            }
          }
        }

        ul {
          padding: 10px 0;
        }
        li {
          > a {
            border-bottom: none;
            margin-left: 1em;
            padding: 10px 15px;
            font-size: 0.879em;
            border-left: 1px solid @brand;
            &:hover {
              border-color: @brand;
            }
          }

          // Expanded list item.
          &.expanded {
            > a {
              margin: 10px 0 0 0;
              font-size: 0.95em;
              border-left: none;
              color: @brand;
            }
            &.active {
              > a {
                padding: 15px 15px;
              }
            }
          }
        }
      }
    }
  }
}

.js .uoe-left-nav {
  > li {display: none;}
}
.js .uoe-left-nav.open {
  > li, > li li {display: block;}
}

@media screen and (min-width: @screen-sm-min) {
  .js {
    .uoe-left-nav > li {display: block;}
    .uoe-nav-trigger {display: none;}
  }
}

@media screen and (max-width: (@screen-xs-max)) {
  .navbar-default .navbar-collapse {
    max-height: 600px;
  }
}

/* Marking secure links */
a .glyphicon-lock {
    font-size: 80%;
    padding-left: 0.5em;
}
