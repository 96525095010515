html {
  overflow-x: hidden;
  font-size: 95%;
  -webkit-text-size-adjust: 100%;
}
@media screen and (min-width: @screen-sm-min) {
  html {font-size: 85%; height: 100%;}
}
@media screen and (min-width: 1024px) {
  html {font-size: 87.5%;}
}
@media screen and (min-width: 1080px) {
  html {font-size: 90%;}
}
@media screen and (min-width: 1200px) {
  html {font-size: 95%;}
}
@media screen and (min-width: 1350px) {
  html {font-size: 100%;}
}
@media screen and (min-width: 1650px) {
  html {font-size: 105%;}
}
@media screen and (min-width: 1900px) {
  html {font-size: 115%;}
}

body {
  font: 100%;
  font-size: 1rem;
  line-height: normal;
}

.content {
  background: #f5f5f5;
  padding-top: 30px;
  padding-bottom: 60px;
}
.jumbotron + .content {
  padding-top: 0;
  > .row > div:last-child {
    margin-top: 30px;
  }
}
.admin-bar + .content {
  padding-top: 20px;
}

img + .caption {
  & > h1, h2, h3, h4, h5, h6 {
    &:first-child {
      padding-top: 0;
      margin-top: 11px;
    }
    &:last-child {
      margin-bottom: 0;
      padding-bottom: 0;
    }
  }
}

.topline {
  border-top: 7px solid @brand;
  border-bottom: 1px solid darken(@brand, 10%);
}

ul, ol {
  padding-left: 1.5em;
}
ul {
  &.glyphicon-chevron-right {
    list-style: none;
    padding: 0;
    &:before {content: ""; display: none;}
    li {position: relative; padding-left: 1.5em;}
    > li:before {
      font-family:'Glyphicons Halflings';
      content:"\e080";
      margin-right: 6px;
      font-size: 0.75em;
      position: absolute;
      top: 0.25em;
      left: 0.5em;
    }
  }
}

.newslist {
  list-style: none;
  margin: 0 0 30px;
  padding: 0;
  li:extend(.clearfix) {
    border-top: 1px solid #ccc;
    padding: 0 0 30px;
    display: table;
  }
  li:first-child {border-top: none;}
  h6 + h6 {
    margin-top: -5px;
  }
}

hr {display: block;
  clear: both;
  border-bottom: 1px solid #ccc;
  margin: 30px 0;
}

small, .small, cite {font-size: 85%; color: #6A6A6A;}
.article-published {display: block; margin-top: 2em;}
blockquote {border-color: #bbb;}
blockquote footer cite span {display: block;}
.author {color: #000;}

.no-resize img, img.no-resize {
  max-width: none;
  margin-left: 0;
  width: auto;
}

.home-hero, figure, .panel {
  img:extend(.shade) {width: 100%; height: auto; padding: 0;}
}

a {cursor: pointer;}

.pagination:extend(.shade) {margin: 30px 0; background: #fff; padding: 0;}
.pagination > li:first-child > a, .pagination > li:first-child > span, .pagination > li:last-child > a, .pagination > li:last-child > span {border-radius: 0;}
.pagination > li > a, .pagination > li > span {padding: 12px 20px; border-width: 0; border-left-width: 1px; border-color: #ddd;}
.pagination > li:first-child > *  {border-left-width: 0;}

.pagination > .active {
  & > a, & > span, & > a:hover, & > span:hover, & > a:focus, & > span:focus {
    background: @brand;
    border-color: @brand;
    .mixin-text-contrast(@brand);
  }
}


.atoz > li > a, .atoz > li > span {padding: 12px 9px;}
@media (min-width: 1200px) {
  .atoz > li > a, .atoz > li > span {padding: 12px 11px;}
}

dl.address {
  dt {display: none;}
  dd {word-wrap: break-word;}
}

.table > thead > tr > th {border-bottom: 1px solid #bbb;}

.keyfacts {
  background: #e8e8e8;
  border: 1px solid #ddd;
  margin: 20px 0 40px;
  padding: 0 30px 30px;
}

.search {
  padding: 20px 0;
  h1, h2, h3 {margin-top: 0;}
}
.featured {
  border: 1px solid #ddd;
  box-shadow: 0 0 0 10px rgba(0, 0, 0, 0.03);
  padding: 20px;
  margin: 0 0 30px;
}

figure figcaption:extend(.panel) {
  color: #526973;
  padding: 11px 20px;
  &:empty {display: none;}
  margin-bottom: 0;
}
.panel figure {
  margin-bottom: 0;
  figcaption {box-shadow: none; background: #eee;}
}

figure {
  margin-bottom: 15px;
  &.pull-left {
    margin-right: 20px;
    padding-left: 0;
    padding-right: 0;
    clear: left;
  }
  &.pull-right {
    margin-left: 20px;
    padding-left: 0;
    padding-right: 0;
    clear: right;
  }
  figcaption {
    clear: both;
  }
}

.video-container {height: 0; margin: 0 0 6px; overflow: hidden; padding-bottom: 56.25%; padding-top: 30px; position: relative;}
.video-container iframe, .video-container object, .video-container embed {height: 100%; left: 0; position: absolute; top: 0; width: 100%;}
.caption {margin-bottom: 10px;}

.media-container {
  margin: 0 auto;
  max-width: 660px;
}

.cta {
  &:extend(.clearfix);
  list-style: none;
  padding: 0;
  margin-bottom: 30px;
  li {float: left; width: 100%;
    a {
      position: relative;
      display: block;
      background: @brand;
      .mixin-text-contrast(@brand);
      padding: 20px 20px 20px 60px;
      margin: 0 5px 5px 0;
      font-size: 16px;
      font-weight: 700;
      .glyphicon {
        opacity: 0.4;
        font-size: 30px;
        position: absolute;
        top: 16px;
        left: 18px;
      }
    }
    a:hover {
      text-decoration:  none;
      background: darken(@brand, 5%);
    }
  }
}
.cta.inverted li a {
  &:extend(.shade, .clearfix);
  background: #fff;
  .mixin-link-contrast(@brand);
  &:hover {background: #fafafa;}
  .glyphicon {
    opacity: 0.2;
    .mixin-link-contrast(@brand);
  }
}

@media screen and (min-width: @screen-xs-min) {
  .cta li {width: 50%;}
  .cta.ctaPairs li {width: 100%;}
}
@media screen and (min-width: 1024px) {
  .cta li {width: 33.33%;}
  .cta.ctaPairs li {width: 50%;}
}

.map {height: 390px;}
.map * {box-shadow: none !important;}

blockquote:extend(.shade) {
  clear: both;
  border-color: @brand;
  background: #fff;
  padding: 20px;
  margin: 0 0 15px;
  font-size: 1.2em;
  line-height: 1.6em;
  font-style: italic;
  quotes: "“" "”" "‘" "’";
}
blockquote + small {
  display: block;
  margin: -20px 0 30px;
}
blockquote p:first-of-type:before, blockquote p:last-of-type:after {
  color: #cccccc;
  content: open-quote;
  font-size: 3.2em;
  line-height: 1px;
  margin-right: 0.25em;
  vertical-align: -27px;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 400;
}
blockquote p:last-of-type:after {
  content: close-quote;
  margin-right: 0;
  margin-left: 0.25em;
}

.sr-only {
  position: absolute !important;
}
