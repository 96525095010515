//
// Panels
// --------------------------------------------------

.panel-heading {
  border: none;
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.15);
}
.panel-title {
  margin-top: 5px 0;
}
.panel-body {
  line-height: 1.428571429;
  padding: 11px 20px;
}
h1.panel-title { font-size: @font-size-h1; }
h2.panel-title { font-size: @font-size-h2; }
h3.panel-title { font-size: @font-size-h3; }

.panel-default {
  .panel-title {
    font-weight: 600;
    font-size: 17px;
  }
}

.panel > .list-group {
  list-style: none;
  border: none;
}
.panel > .list-group .list-group-item {
  border: none;
}

.panel-group .panel-heading + .panel-collapse .panel-body {
  border-top: none;
}
.panel-group .panel-heading {
  background-color: @panel-default-heading-bg;
}
.panel [data-toggle="collapse"] {
  &:hover, &:active, &:focus {
    text-decoration: none;
    outline: none;
  }
  &:before {
    content: "\e082";
    font-family: 'Glyphicons Halflings';
    vertical-align: sub;
    padding-right: 20px;
  }
  &[aria-expanded="false"], &.collapsed {
    &:before {content: "\e081";}
  }
}

.panel-collapse, .panel-group .panel-heading .panel-title a {
  padding-left: 40px;
  &[data-toggle="collapse"] {
    padding-left: 0;
  }
}

.panel.panel-group { padding: 20px;
  .panel {margin-top: 0; box-shadow: none;}
  .panel > .panel-heading {background: none;}
}
.panel > figure {
  padding-bottom: 0;
}

.panel-uoe-high {
  .panel-variant(@brand-color; @brand-bg-text; @brand-color; @brand-color);
  background-color: @brand-color;
  color: @brand-bg-text;

  .panel-heading {
    box-shadow: none;
    padding-bottom: 0;
  }
  .panel-title {
    margin-top: 10px;
    margin-bottom: 0;
  }
  
  a {
    color: @brand-link-color;
    &:hover {
      color: darken(@brand-link-color, 5%);
      text-decoration: underline;
    }
  }
}

.panel-uoe-medium {
  .panel-variant(@panel-default-border; @brand-bg-text; @brand-color; @brand-color);
  
  .panel-heading a {
    color: @brand-link-color;
    &:hover {
      color: darken(@brand-link-color, 5%);
      text-decoration: underline;
    }    
  }
}

.panel-uoe-low {
  .panel-variant(@panel-default-border; @panel-default-text; @panel-bg; @brand-color);
  border-top: 8px solid @brand-color;
  
  .panel-heading {
    box-shadow: none;
    padding-bottom: 0;
  }
  .panel-title {
    margin-top: 10px;
    margin-bottom: 0;
  }
  
  .panel-heading a {
    .mixin-link-contrast(@brand-color);
    &:hover {
      .mixin-hover-contrast(@brand-color);
      text-decoration: underline;
    }
  }
}
